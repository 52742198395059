// src/App.js
import React from 'react';
import ThreeScene from './ThreeScene';
import './App.css';

const App = () => {
  return (
    <div className="app-container">

   
      <ThreeScene />
  
    </div>
  );
};

export default App;
