// src/TwitterFollowButton.js
import React, { useEffect } from 'react';

const TwitterFollowButton = () => {
  useEffect(() => {
    // Load Twitter's script dynamically
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <a
      href="https://twitter.com/Mondoweb3"
      className="twitter-follow-button"
      data-size="large"
    >
      Follow @TwitterDev
    </a>
  );
};

export default TwitterFollowButton;
