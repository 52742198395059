import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import './ThreeScene.css'; // Import the CSS file
import TwitterFollowButton from './TwitterFollowButton'; // Import the TwitterFollowButton component

const ThreeScene = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let renderer, scene, camera, circle, skelet, particle;

    const init = () => {
      // Renderer
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio || 1);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.autoClear = false;
      renderer.setClearColor(0x000000, 0.0);

      // Append the renderer's DOM element to the ref
      const mount = mountRef.current;
      mount.appendChild(renderer.domElement);

      // Scene
      scene = new THREE.Scene();

      // Camera
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 400;
      scene.add(camera);

      // Objects
      circle = new THREE.Object3D();
      skelet = new THREE.Object3D();
      particle = new THREE.Object3D();
      scene.add(circle);
      scene.add(skelet);
      scene.add(particle);

      // Geometry
      const geometry = new THREE.TetrahedronGeometry(2, 0);
      const geom = new THREE.IcosahedronGeometry(7, 1);
      const geom2 = new THREE.IcosahedronGeometry(15, 1);

      // Material
      const material = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        flatShading: true
      });

      for (let i = 0; i < 1000; i++) {
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).normalize();
        mesh.position.multiplyScalar(90 + Math.random() * 700);
        mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
        particle.add(mesh);
      }

      const mat = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        flatShading: true
      });

      const mat2 = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        wireframe: true,
        side: THREE.DoubleSide
      });

      const planet = new THREE.Mesh(geom, mat);
      planet.scale.set(16, 16, 16);
      circle.add(planet);

      const planet2 = new THREE.Mesh(geom2, mat2);
      planet2.scale.set(10, 10, 10);
      skelet.add(planet2);

      // Lights
      const ambientLight = new THREE.AmbientLight(0x999999);
      scene.add(ambientLight);

      const lights = [];
      lights[0] = new THREE.DirectionalLight(0xffffff, 1);
      lights[0].position.set(1, 0, 0);
      lights[1] = new THREE.DirectionalLight(0x11E8BB, 1);
      lights[1].position.set(0.75, 1, 0.5);
      lights[2] = new THREE.DirectionalLight(0x8200C9, 1);
      lights[2].position.set(-0.75, -1, 0.5);
      scene.add(lights[0]);
      scene.add(lights[1]);
      scene.add(lights[2]);

      window.addEventListener('resize', onWindowResize, false);
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      particle.rotation.x += 0.0000;
      particle.rotation.y -= 0.0040;
      circle.rotation.x -= 0.0020;
      circle.rotation.y -= 0.0030;
      skelet.rotation.x -= 0.0010;
      skelet.rotation.y += 0.0020;
      renderer.clear();
      renderer.render(scene, camera);
    };

    init();
    animate();

    return () => {
      const mount = mountRef.current;
      if (mount) {
        mount.removeChild(renderer.domElement);
      }
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <div className="scene-container">
      <div ref={mountRef} id="canvas" />
      <div className="overlay">
        <img src="/logo.png" alt="Logo" className="logo" />
       
        <h1>Join us on X </h1>
       
        <p>COMING SOON JAN 2025</p>
        <div>
        <div>
          <TwitterFollowButton />
        </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeScene;
